import { useTranslation } from "react-i18next";
import CardIdea from "../../components/molecules/Card/CardIdea";
import { ideaCards } from "../../utils/mock";
import Content from "./Content/Content";
import styles from "./Ideas.module.scss";

export const Ideas = () => {
  const { t } = useTranslation("IDEAS");
  return (
    <div className={styles.archivioIdeeDiBusiness}>
      <Content />

      <section className={styles.ideas}>
        <div className={styles.ideasHeader}>
          <h3 className={styles.esploraEVota}>{t("TITLE")}</h3>
          <div className={styles.ideasGrid}>
            <div className={styles.ideaCards}>
              {ideaCards.map((i, index) => (
                <CardIdea
                  key={index}
                  category={i.category}
                  idea_description={i.idea_description}
                  idea_name={i.idea_name}
                  idea_owner={i.idea_owner}
                />
              ))}
            </div>
          </div>
        </div>
        {ideaCards.length > 6 && <button className={styles.button}>
          <img
            className={styles.iconkeyboardArrowLeft}
            alt=""
            src="/iconkeyboard-arrow-left.svg"
          />
          <b className={styles.cta}>{t("BUTTON")}</b>
          <img
            className={styles.iconkeyboardArrowRight}
            alt=""
            src="/iconkeyboard-arrow-right.svg"
          />
        </button>}
      </section>
    </div>
  );
};
