import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Footer from "./components/molecules/Footer";
import Navbar from "./components/molecules/Navbar";
import Home from "./pages/Home";
import IdeaForm from "./pages/IdeaForm";
import Ideas from "./pages/Ideas";
import Stats from "./pages/Stats";
import ThankYouPage from "./pages/ThankYouPage";
import { routes } from "./utils/routes";
import { ScrollToTop } from "./utils/scrollToTop";
import ErrorPage from "./pages/ErrorPage";

function App() {
  const router = createBrowserRouter([
    {
      element: (
        <>
          <Navbar variant="landing" />
          <div className="pageWithHeader">
            <ScrollToTop />
            <Outlet />
            <Footer />
          </div>
        </>
      ),
      children: [
        {
          path: routes.home,
          element: <Home />,
        },
        {
          path: routes.stats,
          element: <Stats />,
        },
        {
          path: routes.ideas,

          element: <Ideas />,
        },
      ],
    },
    {
      path: routes.newIdea,
      element: (
        <>
          <Navbar variant="form" />
          <div className="pageWithHeader">
            <ScrollToTop />
            <IdeaForm />
          </div>
        </>
      ),
    },
    {
      path: routes.thanYouPage,
      element: (
        <>
          <Navbar variant="form" />
          <div className="pageWithHeader">
            <ScrollToTop />
            <ThankYouPage />
          </div>
        </>
      ),
    },
    {
      path: routes.error,
      element: (
        <>
          <Navbar variant="form" />
          <div className="pageWithHeader">
            <ScrollToTop />
            <ErrorPage />
          </div>
        </>
      ),
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
