import axios from "axios";
import { paths } from "./paths";
//3646033491537510
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,PATCH,OPTIONS,GET",
  },
});

export const postSuggestion = (payload: any) =>
  axiosInstance.post(
    paths.suggestion,
    {
      ...payload.answers,
      attachments: payload.attachments,
    },
    {
      headers: {
        "X-Captcha-Token": payload.recaptchaKey,
      },
    }
  );

export const postAtachment = (payload: {
  id: string;
  filename: string;
  file: any;
  captchaKey: string | null;
}) => {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("filename", payload.filename);
  formData.append("file", payload.file);

  return axiosInstance.post(paths.attachment, formData, {
    headers: {
      "X-Captcha-Token": payload.captchaKey,
    },
  });
};

export const retriveSuggetion = (suggestionId: string) =>
  axiosInstance.get(`${paths.suggestion}/${suggestionId}${paths.score}`);
