import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/atoms/Button";
import { StartModal } from "../../../components/organism/Modal/StartModal/StartModal";
import styles from "./Content.module.scss";

export type ContentType = {
  className?: string;
};

const Content: FunctionComponent<ContentType> = ({ className = "" }) => {
  const { t } = useTranslation("IDEAS");
  const [openModal, setOpenModal] = useState(false);
  return (
    <section className={[styles.content, className].join(" ")}>
      <StartModal is_open={openModal} close_modal={() => setOpenModal(false)} />
      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <div className={styles.unArchivioConOltre200IdeeParent}>
            <h1 className={styles.unArchivioCon}>{t("HERO.TITLE")}</h1>
            <div className={styles.esploraIlNostro}>{t("HERO.SUBTITLE")}</div>
          </div>
          <div className={styles.heroCTA}>
            <Button
              contrast
              className={styles.button}
              onClick={() => setOpenModal(true)}
            >
              <b>{t("HERO.NEW_IDEA")}</b>
            </Button>
            <Button
              variant="secondary"
              contrast
              className={styles.button1}
              onClick={() => setOpenModal(true)}
            >
              <b>{t("HERO.CHECK_IDEA")}</b>
            </Button>
          </div>
        </div>
        <img
          className={styles.frame26086421}
          loading="lazy"
          alt=""
          src="/image/frame-2608642-1@2x.png"
        />
      </div>
    </section>
  );
};

export default Content;
