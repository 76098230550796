import { Radio, UploadFile } from "antd";
import { useState } from "react";
import Button from "../../components/atoms/Button";
import { ArrowLeft } from "../../components/atoms/Icon/Icon";
import Input from "../../components/atoms/Input";
import Select from "../../components/atoms/Select";
import Textarea from "../../components/atoms/Textarea";
import CardSelection from "../../components/molecules/Card/CardSelection";
import { Questions, Survey } from "../../utils/mock";
import styles from "./IdeaForm.module.scss";
import FileUpload from "../../components/molecules/FileUpload";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { postSuggestion } from "../../utils/services";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Loader } from "../../components/atoms/Loader/Loader";

export const IdeaForm = () => {
  const { t } = useTranslation("IDEA_FORM");
  const navigate = useNavigate();
  const [fileLists, setFileList] = useState<UploadFile[]>([]);

  const [step, setStep] = useState(-1);
  const [answers, setAnswers] = useState<any>({
    idea_resume: {},
    challenge_and_opportunity: {},
    idea_benefit: {},
    necessary_resources: {},
  });
  const [alwaisAnonymus, setAlwaisAnonymus] = useState<
    "always_anonymous" | "until_publication" | undefined
  >();
  const [showNameInput, setShowNameInput] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const goNext = () => {
    if (step < survey.length - 1) {
      setStep(step + 1);
      if (alwaisAnonymus === "always_anonymous") {
        setAnswers({ ...answers, anonymous: alwaisAnonymus });
      } else {
        setAnswers({ ...answers, anonymous: alwaisAnonymus, name, surname });
      }
    }
    if (step === 3) {
      const uidArray = fileLists.map((i) => i.uid);
      setLoading(true);
      postSuggestion({ answers, attachments: uidArray, recaptchaKey })
        .then((data) => {
          setLoading(false);
          navigate(routes.thanYouPage, { state: data.data.receipt });
        })
        .catch((e) => {
          setLoading(false);
          navigate(routes.error, {
            state: { answers, attachments: uidArray, recaptchaKey },
          });
        });
    }
    const parentPage = document.getElementsByClassName("pageWithHeader")[0];
    parentPage.scrollTo(0, 0);
  };

  const goBack = () => {
    if (step > -1) {
      setStep(step - 1);
    }
  };

  const disabledButton = () => {
    if (step === -1) {
      return (
        !alwaisAnonymus ||
        (alwaisAnonymus === "until_publication" && (!name || !surname))
      );
    } else if (step === survey.length - 1 && recaptchaKey) {
      const category = survey[step].questions[0].category;
      const answersCount = Object.values(answers[category]).filter(
        (i) => typeof i === "string" && i.trim() !== ""
      ).length;
      const questionCount = survey[step].questions.length;
      return answersCount !== questionCount - 1;
    } else {
      const category = survey[step].questions[0].category;
      const answersCount = Object.values(answers[category]).filter(
        (i) => typeof i === "string" && i.trim() !== ""
      ).length;
      const questionCount = survey[step].questions.length;
      return answersCount !== questionCount;
    }
  };

  const inputOnChange = (e: any, i: Questions) => {
    setAnswers({
      ...answers,
      [i.category]: {
        ...answers[i.category],
        [i.question]: e.target.value,
      },
    });
  };

  const survey: Survey[] = [
    {
      title: t("STEP_2.TITLE"),
      questions: [
        {
          label: t("STEP_2.QUESTION_1.LABEL"),
          placeholder: t("STEP_2.QUESTION_1.PLACEHOLDER"),
          type: "text",
          question: "idea_name",
          category: "idea_resume",
        },
        {
          label: t("STEP_2.QUESTION_2.LABEL"),
          placeholder: t("STEP_2.QUESTION_2.PLACEHOLDER"),
          type: "textarea",
          question: "idea_description",
          category: "idea_resume",
        },
        {
          label: t("STEP_2.QUESTION_3.LABEL"),
          placeholder: t("STEP_2.QUESTION_3.PLACEHOLDER"),
          type: "select",
          options: [
            { label: "FinTech", value: "FinTech" },
            { label: "InsureTech", value: "InsureTech" },
            { label: "AgroTech", value: "AgroTech" },
            { label: "EdTech", value: "EdTech" },
            { label: "DevTech", value: "DevTech" },
          ],
          question: "idea_category",
          category: "idea_resume",
        },
        {
          label: t("STEP_2.QUESTION_4.LABEL"),
          placeholder: t("STEP_2.QUESTION_4.PLACEHOLDER"),
          type: "textarea",
          question: "idea_target",
          category: "idea_resume",
        },
        {
          label: t("STEP_2.QUESTION_5.LABEL"),
          placeholder: t("STEP_2.QUESTION_5.PLACEHOLDER"),
          type: "textarea",
          question: "idea_benefit",
          category: "idea_resume",
        },
        {
          label: t("STEP_2.QUESTION_6.LABEL"),
          placeholder: t("STEP_2.QUESTION_6.PLACEHOLDER"),
          type: "textarea",
          question: "idea_competitor",
          category: "idea_resume",
        },
      ],
    },
    {
      title: t("STEP_3.TITLE"),
      questions: [
        {
          label: t("STEP_3.QUESTION_1.LABEL"),
          placeholder: t("STEP_3.QUESTION_1.PLACEHOLDER"),
          type: "textarea",
          question: "business_challenge",
          category: "challenge_and_opportunity",
        },
        {
          label: t("STEP_3.QUESTION_2.LABEL"),
          placeholder: t("STEP_3.QUESTION_2.PLACEHOLDER"),
          type: "textarea",
          question: "business_opportunity",
          category: "challenge_and_opportunity",
        },
        {
          label: t("STEP_3.QUESTION_3.LABEL"),
          placeholder: t("STEP_3.QUESTION_3.PLACEHOLDER"),
          type: "textarea",
          question: "challenge_solution",
          category: "challenge_and_opportunity",
        },
        {
          label: t("STEP_3.QUESTION_4.LABEL"),
          placeholder: t("STEP_3.QUESTION_4.PLACEHOLDER"),
          type: "textarea",
          question: "innovation_element",
          category: "challenge_and_opportunity",
        },
      ],
    },
    {
      title: t("STEP_4.TITLE"),
      subtitle: t("STEP_4.SUBTITLE"),
      questions: [
        {
          label: t("STEP_4.QUESTION_1.LABEL"),
          placeholder: t("STEP_4.QUESTION_1.PLACEHOLDER"),
          type: "textarea",
          question: "customer_benefit",
          category: "idea_benefit",
        },
        {
          label: t("STEP_4.QUESTION_2.LABEL"),
          placeholder: t("STEP_4.QUESTION_2.PLACEHOLDER"),
          type: "textarea",
          question: "business_benefit",
          category: "idea_benefit",
        },
        {
          label: t("STEP_4.QUESTION_3.LABEL"),
          placeholder: t("STEP_4.QUESTION_3.PLACEHOLDER"),
          type: "textarea",
          question: "whole_business_benefit",
          category: "idea_benefit",
        },
      ],
    },
    {
      title: t("STEP_5.TITLE"),
      subtitle: t("STEP_5.SUBTITLE"),
      questions: [
        {
          label: t("STEP_5.QUESTION_1.LABEL"),
          placeholder: t("STEP_5.QUESTION_1.PLACEHOLDER"),
          type: "textarea",
          question: "technologies_resources",
          category: "necessary_resources",
        },
        {
          label: t("STEP_5.QUESTION_2.LABEL"),
          placeholder: t("STEP_5.QUESTION_2.PLACEHOLDER"),
          type: "textarea",
          question: "human_resources",
          category: "necessary_resources",
        },
        {
          label: t("STEP_5.QUESTION_3.LABEL"),
          placeholder: t("STEP_5.QUESTION_3.PLACEHOLDER"),
          type: "textarea",
          question: "financial_resources",
          category: "necessary_resources",
        },
        {
          label: t("STEP_5.QUESTION_4.LABEL"),
          placeholder: t("STEP_5.QUESTION_4.PLACEHOLDER"),
          type: "textarea",
          question: "other_resources",
          category: "necessary_resources",
        },
        {
          label: t("STEP_5.QUESTION_5.LABEL"),
          placeholder: t("STEP_5.QUESTION_5.PLACEHOLDER"),
          type: "textarea",
          question: "next_step",
          category: "necessary_resources",
        },
        {
          label: t("STEP_5.QUESTION_6.LABEL"),
          type: "file",
          question: "idea_attachment",
          category: "necessary_resources",
        },
      ],
    },
  ];

  return (
    <div className={styles.ideaForm}>
      <Loader loading={loading} />
      <div className={styles.stepWrapper}>
        {Array(5)
          .fill("")
          .map((i, index) => (
            <div
              key={index}
              className={`${styles.step} ${
                step === index - 1 && styles.current
              } ${step > index - 1 && styles.done}`}
            ></div>
          ))}
      </div>
      <div className={styles.form}>
        {step < 0 ? (
          <div className={styles.formContainer}>
            <h1>{t("STEP_1.TITLE")}</h1>
            <div className={styles.questionContainer}>
              <h2>{t("STEP_1.SUBTITLE")}</h2>
              <Radio.Group
                value={alwaisAnonymus}
                onChange={(e) => {
                  if (e.target.value === "until_publication") {
                    setShowNameInput(true);
                    setAlwaisAnonymus("until_publication");
                  } else {
                    setShowNameInput(false);
                    setAlwaisAnonymus("always_anonymous");
                  }
                }}
              >
                <div className={styles.answersWrapper}>
                  <CardSelection
                    always_anonymous
                    active={alwaisAnonymus === "always_anonymous"}
                  />
                  <CardSelection
                    active={alwaisAnonymus === "until_publication"}
                    show_name_input={showNameInput}
                    name_value={name}
                    name_onChange={(e) => {
                      setName(e.target.value);
                    }}
                    surname_value={surname}
                    surname_onChange={(e) => {
                      setSurname(e.target.value);
                    }}
                  />
                </div>
              </Radio.Group>
              <p>{t("STEP_1.PRIVACY")}</p>
            </div>
          </div>
        ) : (
          <div className={styles.formContainer}>
            <div className={styles.titleContainer}>
              <Button
                className={styles.backBtn}
                left_icon={<ArrowLeft />}
                variant="text"
                onClick={goBack}
              >
                {t("BUTTON.BACK")}
              </Button>
              <h1>{survey[step].title}</h1>
            </div>
            {survey[step].subtitle && (
              <p className={styles.subtitle}>{survey[step].subtitle}</p>
            )}
            <div className={styles.answersWrapper}>
              {survey[step].questions.map((i, index) => {
                if (i.type === "text") {
                  return (
                    <Input
                      key={index}
                      label={i.label}
                      placeholder={i.placeholder}
                      value={answers[i.category][i.question]}
                      onChange={(e) => inputOnChange(e, i)}
                    />
                  );
                }
                if (i.type === "textarea") {
                  return (
                    <Textarea
                      key={index}
                      label={i.label}
                      placeholder={i.placeholder}
                      value={answers[i.category][i.question]}
                      onChange={(e) => inputOnChange(e, i)}
                    />
                  );
                }
                if (i.type === "select") {
                  return (
                    <Select
                      key={index}
                      label={i.label}
                      placeholder={i.placeholder}
                      options={i.options}
                      value={answers[i.category][i.question]}
                      onChange={(value) => {
                        setAnswers({
                          ...answers,
                          [i.category]: {
                            ...answers[i.category],
                            [i.question]: value,
                          },
                        });
                      }}
                    />
                  );
                }
                if (i.type === "file") {
                  return (
                    <>
                      <ReCAPTCHA
                        style={{ width: "fit-content", margin: "0 auto" }}
                        sitekey={process.env.REACT_APP_GOOGLE_ID!}
                        onChange={(key) => {
                          setRecaptchaKey(key);
                        }}
                      />
                      <FileUpload
                        key={index}
                        label={i.label}
                        file_lists={fileLists}
                        set_file_list={setFileList}
                        captchaKey={recaptchaKey}
                      />
                    </>
                  );
                }
              })}
            </div>
            {step === survey.length - 1 && (
              <p className={styles.thankYou}>{t("STEP_5.THANYOU")}</p>
            )}
          </div>
        )}
        <div className={styles.ctaContainer}>
          <div className={styles.requiredField}>{t("FIELD_REQUIRED")}</div>
          <Button
            className={styles.formBtn}
            disabled={disabledButton()}
            onClick={goNext}
          >
            {step < 3 ? t("BUTTON.NEXT") : t("BUTTON.SEND")}
          </Button>
        </div>
      </div>
    </div>
  );
};
