import { useEffect, useState } from "react";
import ImageCardSection from "../../components/organism/ImageCardSection";
import styles from "./Stats.module.scss";
import { StartModal } from "../../components/organism/Modal/StartModal/StartModal";
import { useTranslation } from "react-i18next";

export const Stats = () => {
  const { t } = useTranslation("STATS");
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={styles.numeri}>
      <StartModal close_modal={() => setOpenModal(false)} is_open={openModal} />
      <main className={styles.mainContent}>
        <section className={styles.contentHeader}>
          <h3 className={styles.tuttiINumeri}>{t("TITLE")}</h3>
          <div className={styles.statistics}>
            <div className={styles.statistiche}>
              <div className={styles.statisticsData}>
                <b className={styles.emptyData}>231</b>
                <h3 className={styles.ideeInnovativeProposte}>
                  {t("CARD_1.TITLE")}
                </h3>
                <div className={styles.numeroseOpportunitImprendit}>
                  {t("CARD_1.DESCRIPTION")}
                </div>
              </div>
            </div>
            <div className={styles.statistiche1}>
              <div className={styles.parent}>
                <b className={styles.b}>6</b>
                <h3 className={styles.ideeInnovativeProposte1}>
                  {t("CARD_2.TITLE")}
                </h3>
                <div className={styles.numeroseOpportunitImprendit1}>
                  {t("CARD_2.DESCRIPTION")}
                </div>
              </div>
            </div>
            <div className={styles.statistiche2}>
              <div className={styles.group}>
                <b className={styles.b1}>11</b>
                <h3 className={styles.ideeInnovativeProposte2}>
                  {t("CARD_3.TITLE")}
                </h3>
                <div className={styles.numeroseOpportunitImprendit2}>
                  {t("CARD_3.DESCRIPTION")}
                </div>
              </div>
            </div>
          </div>
        </section>
        <ImageCardSection
          variant="dark"
          cta_action={() => setOpenModal(true)}
          cta_text={t("IMAGE_CARD.BUTTON")}
          description={t("IMAGE_CARD.DESCRIPTION")}
          image="/image/stats_image.png"
          title={t("IMAGE_CARD.TITLE")}
        />
      </main>
    </div>
  );
};
